import client from './axios.js'

export default {
  getScheduleList(idBrand) {
    return client.get(`/message/mobile/list/${idBrand}`)
  },
  getSchedule(idSchedule) {
    return client.get(`/message/mobile/schedule/${idSchedule}`)
  },
  getTags(idBrand) {
    return client.get(`/tag/message/mobile/${idBrand}`)
  },
  createSchedule(schedule) {
    return client.post('/message/mobile/schedule', schedule)
  },
  editSchedule(schedule) {
    return client.put('/message/mobile/schedule', schedule)
  },
  deleteSchedule(idSchedule) {
    return client.delete(`/message/mobile/schedule/${idSchedule}`)
  },
}
