import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import VuexPersist from 'vuex-persist'

//Modules
import user from './modules/user.js'
import message from './modules/message.js'
import schedule from './modules/schedule.js'
import player from './modules/player.js'
import playlist from './modules/playlist.js'
import musique from './modules/musique.js'
import campaign from './modules/campaign.js'
import stripe from './modules/stripe.js'

const vueLocalStorage = new VuexPersist({
  key: 'STORAGE_KEY',
  storage: window.localStorage,
  reducer: (state) => ({
    user: {
      user: state.user.user,
      selectedSite: state.user.selectedSite,
    },
    message: {
      record: state.message.record,
    },
    stripe: {
      customerId: state.stripe.customerId,
    },
  }),
})

export default new Vuex.Store({
  modules: {
    user,
    message,
    schedule,
    player,
    playlist,
    musique,
    campaign,
    stripe,
  },
  plugins: [vueLocalStorage.plugin],
  strict: process.env.NODE_ENV !== 'production',
})
