import messageService from '@/services/messageService.js'
const getDefaultState = () => {
  return {
    record: JSON.parse(localStorage.getItem('record')) || {},
    tags: JSON.parse(localStorage.getItem('tags')) || null,
    doneTagReq: false,
  }
}
const message = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    SET_RECORD(state, record) {
      state.record = Object.assign({}, state.record, record)
    },
    SET_TAGS(state, tags) {
      state.tags = tags
      state.doneTagReq = true
    },
    START_TAG_REQ(state) {
      state.doneTagReq = false
    },
    CLEAR_RECORD(state) {
      state.record = {}
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    _saveMessage({ commit }, audioRecord) {
      commit('SET_RECORD', audioRecord)
    },
    _clearMessage({ commit }) {
      commit('CLEAR_RECORD')
    },
    async _getTags({ commit, rootState }) {
      commit('START_TAG_REQ')
      const res = await messageService.getTags(
        rootState.user.user.visibleBrands[0].id
      )

      localStorage.setItem('tags', JSON.stringify(res.data))
      commit('SET_TAGS', res.data)
    },
    _resetMessageState({ commit }) {
      commit('RESET_STATE')
    },
  },
  getters: {},
}

export default message
