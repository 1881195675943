import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import store from './store'
import router from './router/router.js'
Vue.use(VueRouter)

// Globally register all base components
import '@/components/base/_registerBaseComponents'

//Import plugins
import '@/plugins/boostrap-vue.js'
import i18n from '@/plugins/i18n.js'
import '@/plugins/vuelidate.js'
import '@/plugins/circle-timer.js'
import '@/plugins/v-calendar.js'
import '@/plugins/vue-moment.js'
import '@/plugins/toast-notification.js'
import '@/plugins/vue-select.js'
import '@/plugins/vue-multi-select.js'
import '@/plugins/smooth-scroll.js'
import MusiqueCalendar from '@/components/MusiqueCalendar'
import '@/plugins/font-awesome.js'
import '@/plugins/zendesk.js'
import '@/plugins/splitbee.js'
import '@/plugins/stripe-vue.js'
import '@/plugins/flag-icon.js'

Vue.config.productionTip = false

Vue.filter('lowercase', function(value) {
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

new Vue({
  router,
  store,
  i18n,
  MusiqueCalendar,
  render: (h) => h(App),
}).$mount('#app')
