<template>
  <div>
    <b-card
      class="border-0 h-100"
      :class="{
        'shadow-card': !noShadow,
        'no-padding': p0,
        'hover-grey': hoverGrey,
      }"
    >
      <slot></slot>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    noShadow: {
      type: Boolean,
      default: false,
    },
    p0: Boolean,
    hoverGrey: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.shadow-card {
  box-shadow: 0 3px 4px 0 $h-box-shadow;
}
.px-0 .card-body {
  padding: 0;
}
.card-body {
  display: flex;
  flex-direction: column;
}
.hover-grey {
  transition: 0.2s;
  &:hover {
    background: $h-hover-color;
  }
}
</style>
