<template>
  <div class="locale-changer">
    <h-select
      @input="changeLanguage"
      v-model="language"
      :placeholder="$t('player.playerTypeLabel')"
      :options="langs"
      notClearable
      text-field="text"
      :flag="language.flag"
    ></h-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: this.$i18n.locale,
      langs: [
        { value: 'fr', flag: 'fr', text: 'Français' },
        { value: 'en', flag: 'gb', text: 'English' },
      ],
    }
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.language.value
      localStorage.setItem('lang', JSON.stringify(this.language))
    },
  },
  created() {
    const savedLanguage = localStorage.getItem('lang')
    if (savedLanguage) {
      const savedLanguageParsed = JSON.parse(savedLanguage)
      this.language = savedLanguageParsed
      this.$i18n.locale = savedLanguageParsed.value
    }
  },
}
</script>

<style lang="scss" scoped></style>
