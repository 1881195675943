var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"planif-container"},[_c('div',{staticClass:"planif-main"},[_c('div',{staticClass:"right-actions justify-content-end no-white-space d-flex align-items-center"},[_c('div',{staticClass:"w-50 d-none d-xl-flex justify-content-end no-white-space align-items-center"},[(_vm.player)?_c('b-col',{attrs:{"cols":"12","xl":"10","lg":"8"}},[(_vm.fluxOptions && _vm.fluxOptions.length > 0)?_c('h-select-flux',{staticClass:"w-100 mr-3",attrs:{"placeholder":_vm.$t('planification.chooseAProgram'),"playerType":_vm.player.offer,"options":_vm.fluxOptions,"disabled":_vm.fluxOptions.length === 1},on:{"input":_vm.changeProgram},model:{value:(_vm.flux),callback:function ($$v) {_vm.flux=$$v},expression:"flux"}}):_vm._e()],1):_vm._e()],1),(_vm.player && _vm.fluxOptions)?_c('div',{staticClass:"d-flex d-xl-none"},[(
            (_vm.fluxOptions && _vm.fluxOptions.length > 0) || _vm.visibleSites.length > 1
          )?_c('h-button',{staticClass:"mr-3",attrs:{"flat":"","grey":"","id":"tooltip-filters"}},[_c('b-icon',{staticClass:"w-15px mr-0 mr-sm-2",attrs:{"icon":"filter","font-scale":"1"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t('player.filters')))])],1):_vm._e(),(_vm.fluxOptions.length > 0 || _vm.visibleSites.length > 1)?_c('b-tooltip',{staticClass:"musique-tooltip",attrs:{"target":"tooltip-filters","variant":"light","placement":"bottom"}},[_c('b-row',{staticClass:"row-tooltip"},[_c('b-col',{staticClass:"pr-1",attrs:{"cols":"12"}},[(_vm.fluxOptions.length > 0 && _vm.player)?_c('h-select-flux',{staticClass:"w-100",attrs:{"placeholder":_vm.$t('planification.chooseAProgram'),"playerType":_vm.player.offer,"options":_vm.fluxOptions},on:{"input":function($event){return _vm.getSchedules()}},model:{value:(_vm.flux),callback:function ($$v) {_vm.flux=$$v},expression:"flux"}}):_vm._e()],1)],1)],1):_vm._e()],1):_vm._e(),(
          _vm.$store.state.user &&
            _vm.$store.state.user.user &&
            _vm.$store.state.user.user.rights &&
            _vm.$store.state.user.user.rights.includes(
              'MYHUBCAST_SCHEDULE_MUSIC_SEQUENCE'
            )
        )?_c('h-button',{attrs:{"disabled":!_vm.player || !_vm.allowToGenerate,"green":""},on:{"click":function($event){return _vm.validatePlanif()}}},[_c('b-icon',{staticClass:"w-15px mr-0 mr-sm-2",attrs:{"icon":"calendar-check-fill","font-scale":"1"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t('planification.validate')))])],1):_vm._e()],1),_c('div',{staticClass:"relative h-100"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.fetchingSchedules)?_c('div',{staticClass:"mask d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"text-white d-flex align-items-center"},[_c('b-spinner',{staticClass:"spinner mr-3"}),_c('span',{staticClass:"lead"},[_vm._v(_vm._s(_vm.saving ? _vm.$t('planification.save') : _vm.$t('planification.get'))+" "+_vm._s(_vm.$t('planification.loadingMessage')))])],1)]):_vm._e()]),_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('b',[_vm._v(_vm._s(_vm.formatTimeText(arg.timeText)))]),_vm._v(" "),_c('br'),_c('i',[_vm._v(_vm._s(arg.event.title))]),(
              arg.event.extendedProps.imgSkeleton &&
                arg.event.extendedProps.imgSkeleton > 0
            )?_c('img',{attrs:{"src":(_vm.env === 'production' ? '/api' : 'http://localhost:5000/api') +
                "/skeleton/" + (arg.event.extendedProps.imgSkeleton) + "/cover","alt":"image skeleton"}}):_vm._e()]}}])})],1)]),(_vm.player && _vm.showValidatePlanifModal)?_c('ModalValidatePlanif',{attrs:{"showValidatePlanifModal":_vm.showValidatePlanifModal,"player":_vm.selectedSite,"editingFlux":_vm.flux,"currentFlux":_vm.player.extended.currentStream},on:{"close":function($event){_vm.showValidatePlanifModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }