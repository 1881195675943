import Vue from 'vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'

const options = {
  pk:
    'pk_test_51L0SqSIOStoHCRM9LQQ8A25Wju9d4VRCAOlgcvMOkpZYEF8Jtp9UksGeSbLkxbxrKJrANSXJbZjW7WjXZxdBT6nV00WzL5bux6',
  testMode: true, // Boolean. To override the insecure host warning
  locale: 'fr',
}

Vue.use(StripePlugin, options)
