<template>
  <div>
    <!-- Desktop Menu -->
    <b-navbar
      id="nav"
      variant="faded"
      type="light"
      fixed="top"
      class="desktop-nav mb-4 py-0 shadow-xs is-fixed-top bg-white"
    >
      <div class="d-md-none menu-button" v-b-toggle.mobile-menu>
        <div class="burger-container">
          <div class="stick stick-1"></div>
          <div class="stick stick-2"></div>
          <div class="stick stick-3"></div>
        </div>
      </div>
      <router-link
        class="nav-link-1"
        v-if="isSingleSite"
        :to="{ name: 'player', params: { id: singleVisibieSite } }"
      >
        <b-navbar-brand class="main-logo py-0">
          <img src="@/assets/svg/myHubcast-logo.svg" alt="myHybcast logo" />
        </b-navbar-brand>
      </router-link>
      <router-link v-else :to="{ name: 'players' }">
        <b-navbar-brand class="main-logo py-0">
          <img src="@/assets/svg/myHubcast-logo.svg" alt="myHybcast logo" />
        </b-navbar-brand>
      </router-link>

      <b-navbar-nav class="desktop-nav-items d-none d-md-flex">
        <b-nav-item v-if="isSingleSite" class="nav-link-1">
          <router-link
            :to="{ name: 'player', params: { id: singleVisibieSite } }"
          >
            Player
          </router-link>
        </b-nav-item>
        <b-nav-item v-else class="nav-link-1">
          <router-link :to="{ name: 'players' }">
            Players
          </router-link>
        </b-nav-item>
        <b-nav-item v-if="hasTabMusic" class="nav-link-1">
          <router-link :to="{ name: 'planification' }">
            {{ $t('menu.scheduling') }}
          </router-link>
        </b-nav-item>
        <b-nav-item v-if="hasTabMessages" class="nav-link-1">
          <router-link :to="{ name: 'messages' }">
            Messages
          </router-link>
        </b-nav-item>
        <b-nav-item-dropdown class="show nav-link-1" :text="$t('menu.profile')">
          <router-link
            tag="b-dropdown-item"
            :to="{ name: 'compte' }"
            target="_self"
            role="menuitem"
          >
            <b-icon icon="person" class="mr-2"></b-icon>
            {{ $t('menu.myAccount') }}
          </router-link>
          <!-- <router-link
            tag="b-dropdown-item"
            :to="{ name: 'abonnement' }"
            target="_self"
            role="menuitem"
          >
            <b-icon icon="credit-card" class="mr-2"> </b-icon>
            Abonnement
          </router-link> 
          <router-link
            :to="{ name: 'aide' }"
            tag="b-dropdown-item"
            target="_self"
            role="menuitem"
          >
            <b-icon icon="life-preserver" class="mr-2"></b-icon>Aide & support
          </router-link>
          -->
          <b-dropdown-item @click="logOut">
            <b-icon icon="box-arrow-right" class="mr-2"></b-icon
            >{{ $t('menu.disconnect') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-brand
        v-if="!this.hideSiteSelect.find((v) => $route.name === v)"
        class="h-100 ml-auto mr-0 d-flex align-items-center"
      >
        <h-multi-select-site
          :placeholder="$t('menu.searchSite')"
          left-icon="shop"
          :options="sites"
          :selectedSite="selectedSite"
          @input="changeSite"
        ></h-multi-select-site>
        <!-- <b-icon
          icon="caret-down-fill"
          class="text-secondary mr-1"
          scale="0.5"
        ></b-icon> -->
      </b-navbar-brand>
    </b-navbar>
    <!-- Mobile sidebar Menu -->
    <b-sidebar
      id="mobile-menu"
      title="Menu"
      shadow
      class="d-lg-none"
      width="300px"
      backdrop-variant="dark"
      backdrop
    >
      <div class="py-2">
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item v-if="isSingleSite" class="nav-link-1">
              <router-link
                :to="{ name: 'player', params: { id: singleVisibieSite } }"
              >
                Player
              </router-link>
            </b-nav-item>
            <b-nav-item v-else class="nav-link-1">
              <router-link :to="{ name: 'players' }">
                Players
              </router-link>
            </b-nav-item>
            <b-nav-item v-if="hasTabMusic" class="nav-link-1">
              <router-link :to="{ name: 'planification' }">
                {{ $t('menu.scheduling') }}
              </router-link>
            </b-nav-item>
            <b-nav-item v-if="hasTabMessages" class="nav-link-1">
              <router-link :to="{ name: 'messages' }">
                Messages
              </router-link>
            </b-nav-item>
            <b-nav-item class="nav-link-1">
              <router-link
                :to="{ name: 'compte' }"
                target="_self"
                role="menuitem"
                >{{ $t('menu.myAccount') }}
              </router-link>
            </b-nav-item>
            <b-nav-item class="nav-link-1">
              <b-dropdown-item @click="logOut">
                <b-icon icon="box-arrow-right" class="mr-2"></b-icon>
                {{ $t('menu.disconnect') }}
              </b-dropdown-item>
            </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      hideSiteSelect: ['players', 'profil'],
    }
  },
  methods: {
    async logOut() {
      localStorage.removeItem('STORAGE_KEY')
      this.$store
        .dispatch('user/_logOut')
        .then(() => {
          this.$store.dispatch('schedule/_clearSchedules')
          this.$router.push({ name: 'login' })
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
    },
    changeSite(val) {
      this.$store.commit('user/SET_SELECTED_SITE', val)
    },
  },
  computed: {
    hasTabMusic() {
      return this.rights.includes('MYHUBCAST_TAB_MUSIC')
    },
    hasTabMessages() {
      return this.rights.includes('MYHUBCAST_TAB_MESSAGE_RECORDING')
    },
    ...mapState({
      rights: (state) => state.user.user.rights,
      sites: (state) => state.user.user.visibleSites,
      selectedSite: (state) => state.user.selectedSite,
    }),
    ...mapGetters({
      isSingleSite: 'user/isSingleSite',
      singleVisibieSite: 'user/singleVisibieSite',
      logoSrc: 'user/logoSrc',
    }),
  },
  mounted() {
    if (!this.selectedSite) {
      const defaultSite = this.$store.state.user.user.visibleSites[0]
      this.$store.commit('user/SET_SELECTED_SITE', defaultSite)
    }

    this.$store.dispatch('user/_fetchBrand')
    this.$nextTick(() => {
      window.addEventListener('scroll', function() {
        var navbar = document.getElementById('nav')
        if (navbar) {
          var nav_classes = navbar.classList
          if (document.documentElement.scrollTop >= 150) {
            if (nav_classes.contains('shrink') === false) {
              nav_classes.toggle('shrink')
            }
          } else {
            if (nav_classes.contains('shrink') === true) {
              nav_classes.toggle('shrink')
            }
          }
        }
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.selected-site-info {
  font-size: 13px;
  color: $h-main-text;
  span {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
